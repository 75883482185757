/** @jsxRuntime classic */
/** @jsx jsx */
import Slider from 'react-slick';
import { jsx, Box, Container } from 'theme-ui';
import { useMediaQuery } from 'react-responsive';
import PriceTable from 'components/cards/price-table';
import SectionHeading from 'components/section-heading';

import 'assets/css/react-slick.css';


const settings = {
  arrows: false,
  dots: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 100000,
      settings: 'unslick',
    },
    {
      breakpoint: 1024,
      settings: {
        fade: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        fade: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        fade: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Pricing = () => {

  const prices = [
    {
      "id": 0,
      "title": "Free",
      "button": "Get Started",
      "allLanguages": true,
      "docs": "500 docs/month",
      "cli": false,
      "dataResidency": false,
      "compliance": false,
      "prioritySupport": false
    },
    {
      "id": 1,
      "title": "Enterprise - Coming Soon",
      "button": "calum@trelent.net",
      "allLanguages": true,
      "docs": "Unlimited",
      "cli": true,
      "dataResidency": true,
      "compliance": true,
      "prioritySupport": true
    },
  ]

  const isTablet = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  // Ensure our styles use the price count
  const styles = {
    section: {
      pt: [6, null, 0, null, 10, 12],
      pb: [8, null, null, null, 10, 14],
    },
    grid: {
      maxWidth: 1133,
      display: [null, null, null, null, 'grid'],
      alignItems: [null, null, null, null, 'center'],
      justifyContent: [null, null, null, null, 'center'],
      gridTemplateColumns: [null, null, null, null, '183px 1fr'],
      mx: 'auto',
      '.slick-list': {
        paddingTop: ['31px', null, null, null, 0],
      },
      '.slick-dots': {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        li: {
          display: 'flex',
        },
        button: {
          backgroundColor: '#CED7E1',
          border: 0,
          outline: 0,
          padding: 0,
          margin: '0 3.5px',
          width: 10,
          height: 10,
          borderRadius: '50%',
          overflow: 'hidden',
          textIndent: '-9999rem',
          transition: 'all 0.3s ease-in-out 0s',
        },
        '.slick-active button': {
          backgroundColor: 'heading',
          width: 13,
          height: 13,
        },
      },
    },
    priceGroup: {
      gap: 2,
      alignItems: 'flex-end',
      display: [null, null, null, null, 'grid'],
      gridTemplateColumns: [null, null, null, null, `repeat(${prices.length}, 1fr)`],
    },
    features: {
      m: 0,
      p: 0,
      listStyle: 'none',
      transform: [
        null,
        null,
        null,
        null,
      ],
      li: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        minHeight: [null, null, null, null, 50, 59],
        '+ li': {
          borderTop: (t) => `1px solid ${t.colors.borderColor}`,
        },
      },
    },
  };

  const pricingTable = (
    <Box sx={styles.grid}>
      {isTablet && (
        <ul sx={styles.features}>
          <li>All Supported Languages</li>
          <li>Usage Limits</li>
          <li>Access to CLI</li>
          <li>Data Residency Control</li>
          <li>HIPAA & SOC 2 Compliance</li>
          <li>Priority support</li>
        </ul>
      )}

      <Slider sx={styles.priceGroup} {...settings}>
        {prices.map((price) => (
          <PriceTable key={price.id} data={price} />
        ))}
      </Slider>
    </Box>
  )

  return (
    <section id="pricing" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={{ mb: [6, null, null, 14] }}
          slogan="Increase developer efficiency by up to 10% per day"
          title="Billed monthly."
        />
        { pricingTable }
      </Container>
    </section>
  );
};

export default Pricing;