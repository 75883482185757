import React from 'react';
import Seo from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import Services from 'sections/services';
import Pricing from 'sections/pricing';
import Blog from 'sections/blog';
import Faq from 'sections/faq';

export default function IndexPage() {

  return (
    <Layout>
      <Seo
        title="Docstrings on Demand"
        description="Documentation sucks. Let us take care of it."
      />
      <Banner />
      <Services />
      <Pricing />
      <Blog disabled={true} />
      <Faq />
    </Layout>
  );
}
