/** @jsx jsx */
import { CodeBlock, vs2015 } from "react-code-blocks";
import { IoIosArrowForward } from 'react-icons/io';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { jsx, Box, Grid, Container, Button, Heading, Text } from 'theme-ui';

const Banner = () => {

  const csharpContent = `  /// <summary>The mult_nums function returns the product of an array of integers.</summary>
  /// 
  /// <param name="nums">The array of integers to multiply.</param>
  /// <return>The product of the numbers array.</return>
  /// 
  /// <doc-author>Trelent</doc-author>
  ///
  private int mult_nums(int[] numbers)
  {
      int prod = 1;
      for(int i = 0; i < numbers.length; i++)
      {
        prod *= numbers[i];
      }
      
      return prod;
  }`;

  const javascriptContent = `/**
 * The multNums function gets the product of an array of numbers.
 *
 * @param nums - The array of numbers to multiply.
 * @return The product of the numbers array.
 * 
 * @doc-author Trelent
 */
function multNums( nums ) {
   let result = 1;
   for(num of nums){
      result *= num;
   }
   return result;
}`;

  const javaContent = `/**
 * The roll function simulates the rolling of a die of <code>iMyNumSides</code> sides.
 *
 * 
 * @return The result of a random roll.
 * 
 * @doc-author Trelent
 */
public int roll()
{   
   iMyResult = ourRandNumGen.nextInt(iMyNumSides) + 1;
   assert ( 1 <= getResult() ) && ( getResult() <= getNumSides() );
   return iMyResult;
}`;

  const pythonContent = `def dijkstra(graph, start_vertex):
  """
  The dijkstra function takes in a graph and a starting vertex. It then performs Dijkstra's algorithm to find the shortest path
  from the start_vertex to all other vertices in the graph. The function returns a dictionary of distances, where each key is an
  index of one of the vertices and its value is that vertex's distance from start_vertex.

  :param graph: Used to Store the graph.
  :param start_vertex: Used to Indicate the vertex from which we start our search.
  :return: A dictionary of distances from the start vertex to all other vertices.

  :doc-author: Trelent
  """
  D = {v:float('inf') for v in range(graph.v)}
  D[start_vertex] = 0

  pq = PriorityQueue()
  pq.put((0, start_vertex))

  while not pq.empty():
      (dist, current_vertex) = pq.get()
      graph.visited.append(current_vertex)

      for neighbor in range(graph.v):
          if graph.edges[current_vertex][neighbor] != -1:
              distance = graph.edges[current_vertex][neighbor]
              if neighbor not in graph.visited:
                  old_cost = D[neighbor]
                  new_cost = D[current_vertex] + distance
                  if new_cost < old_cost:
                      pq.put((new_cost, neighbor))
                      D[neighbor] = new_cost
  return D`;

  const openBannerLinkOnClick = () => {
    window.open(`vscode:extension/Trelent.trelent`, '_blank');
  }

  const openBannerLinkOnPress = (event) => {
    if(event.keyCode === 13) {
      window.open(`vscode:extension/Trelent.trelent`, '_blank');
    }
  }

  return (
    <section id="home" sx={styles.section}>
      <Container sx={styles.container}>
        <Grid sx={styles.grid} gap={4}>
          <Box sx={styles.content} style={{marginTop: '5rem', marginBottom: '5rem'}}>
            <div sx={styles.newsUpdate} onClick={openBannerLinkOnClick} onKeyPress={openBannerLinkOnPress} role="button" tabIndex={0}>
              <span>NEW</span>
              Download our free VS Code extension today!
              <div sx={styles.btnHover}>
                <IoIosArrowForward
                  size="24px"
                />
              </div>
            </div>
            <Heading as="h1" textAlign="center">
              Documentation is tedious.<br/> Let us take care of it.
            </Heading>
            <Text as="p" style={{marginLeft: 'auto', marginRight: 'auto'}}>
              Trelent uses state of the art AI to write docstrings that explain the <i>why</i> of your code instead of the <i>what</i>.
            </Text>
            <div sx={styles.buttonGroup}>
              <Button sx={styles.button} variant="primary" onClick={() => window.open(`vscode:extension/Trelent.trelent`, '_blank')}>
                Add to VS Code<HiOutlineChevronRight/>
              </Button>
              <Button sx={styles.button} variant="primary" onClick={() => window.open(`https://plugins.jetbrains.com/plugin/18716-trelent--ai-docstrings-on-demand`, '_blank')}>
                Add to IntelliJ<HiOutlineChevronRight/>
              </Button>
            </div>
          </Box>
          <Box sx={styles.codeBlocks} style={{marginBottom: '5rem'}}>
            <Tabs>
              <TabList>
                <Tab>Python</Tab>
                <Tab>Java</Tab>
                <Tab>Javascript</Tab>
                <Tab>C#</Tab>
              </TabList>
              <TabPanel>
                <CodeBlock
                  text={pythonContent}
                  language={"python"}
                  showLineNumbers={true}
                  theme={vs2015}
                />
              </TabPanel>
              <TabPanel>
                <CodeBlock
                  text={javaContent}
                  language={"java"}
                  showLineNumbers={true}
                  theme={vs2015}
                />
              </TabPanel>
              <TabPanel>
                <CodeBlock
                  text={javascriptContent}
                  language={"javascript"}
                  showLineNumbers={true}
                  theme={vs2015}
                />
              </TabPanel>
              <TabPanel>
                <CodeBlock
                  text={csharpContent}
                  language={"java"}
                  showLineNumbers={true}
                  theme={vs2015}
                />
              </TabPanel>
            </Tabs>
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default Banner;

const styles = {
  section: {
    pt: [8, null, null, null, 10, 0],
    pb: [8, null, null, null, 10, 0],
  },
  container: {
    px: [3, null, null, 6],
  },
  grid: {
    alignItems: ['center'],
    gridTemplateColumns: ['1fr', null, null, null, null],
    minHeight: [null, null, null, null, '60vh', '100vh'],
    pt: [15, null, null, 17, 12],
  },
  content: {
    textAlign: ['center', null, null, null, 'center'],
    h1: {
      color: 'text',
      fontWeight: 'bold',
      fontSize: [8, null, null, null, 12, 14, 15],
      lineHeight: [1.1, null, null, null, 1.2],
      letterSpacing: 'heading',
    },
    p: {
      color: 'textSecondary',
      fontSize: [2, null, null, 4],
      lineHeight: 1.5,
      maxWidth: 480,
      mx: [null, null, null, 'auto', 'unset'],
      marginTop: [1, null, null, 3],
    },
  },
  newsUpdate: {
    backgroundColor: 'muted',
    boxShadow: '0px 2px 8px rgba(67, 99, 136, 0.07)',
    borderRadius: 50,
    cursor: 'pointer',
    padding: '4px 15px 4px 5px',
    display: 'inline-flex',
    alignItems: 'center',
    color: 'textSecondary',
    fontSize: ['12px', null, null, 1],
    mb: 4,
    textAlign: 'left',
    span: {
      backgroundColor: 'primary',
      color: 'white',
      fontSize: ['10px', null, null, '13px'],
      fontWeight: 700,
      display: 'inline-flex',
      minHeight: 24,
      alignItems: 'center',
      px: '11px',
      borderRadius: 50,
      mr: '12px',
    },
  },
  buttonGroup: {
    mt: [4, null, null, null, 6],
    button: {
      minHeight: 45,
      marginRight: 5,
      marginTop: [5, null],
      px: ['17px', 4],
      svg: {
        transition: 'margin-left 0.3s ease-in-out 0s',
        pl: '3px',
      },
      ':hover': {
        svg: {
          pl: '5px',
        },
      },
    },
    '.white': {
      boxShadow: '0px 4px 6px rgba(125, 128, 170, 0.08)',
      borderRadius: 5,
      fontWeight: 500,
      ml: 3,
    },
  },
  codeBlocks: {
    maxWidth: [null, null, null, '80%', '100%'],
    mx: [null, null, null, 'auto', 'unset'],
    mt: [6, null, null, null, 0],
  }
};
