/** @jsx jsx */
import { jsx, Container } from 'theme-ui';
import {useState, useEffect} from "react";
import Masonry from 'react-masonry-component';
import SectionHeading from 'components/section-heading';
import Post from 'components/cards/post';

const masonryOptions = {
  originTop: false,
};

const Blog = (props) => {
  // Local state
  const [blogPosts, setBlogPosts] = useState([]);

  const { disabled} = props;

  const query = `
  {
    blogPostCollection {
      items {
        id,
        image {
          url,
          description
        },
        title,
        snippet
      }
    }
  }
  `

  useEffect(() => {
    let tempBlogs = [];

    if(disabled === false) {
      window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/djejiu8c4qza/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer -grKTb33iRj0cX44DgbcmXPSBUIgMS62wf5xXSTlqBs",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
  
        // rerender the entire component with new data
        tempBlogs = data.blogPostCollection.items;
        tempBlogs.sort((a, b) => (a.id > b.id) ? 1 : -1);
        setBlogPosts(tempBlogs);
      });
    }
  }, [disabled, query]);

  if(disabled === false) {
    return (
      <section id="blog" sx={styles.section}>
        <Container>
          <SectionHeading
            sx={styles.heading}
            slogan="Insights into Developer Productivity"
            title="Recent Blog Posts"
          />
          <Masonry sx={styles.masonry} options={masonryOptions}>
            {blogPosts.map((post) => (
              <Post key={post.id} post={post} />
            ))}
          </Masonry>
        </Container>
      </section>
    );
  }

  return null;
};

export default Blog;

const styles = {
  section: {
    backgroundColor: 'muted',
    pt: [6, null, null, null, 2, 10, 14],
    pb: [2, null, null, null, 6, 10, 14],
  },
  heading: {
    mb: [6, null, null, 14],
    h3: {
      color: 'white',
    },
  },
  masonry: {
    maxWidth: 1250,
    mx: [-3, null, null, null, 'auto'],
  },
};
