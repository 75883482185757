/** @jsx jsx */
import { jsx, Box, Image, Grid, Heading, Text } from 'theme-ui';
import { LearnMore } from 'components/link';

const Feature = ({ data, ...props }) => {
  const isBrowser = typeof window !== "undefined";
  const isMobile = isBrowser && window.innerWidth <= 1024;

  if(data.id % 2 === 0 && !isMobile) {
    return (
      <Grid gap={2} columns={[1, null, 2]} {...props} sx={styles.feature}>
        <Box as="figure" sx={{marginBottom: '2rem'}}>
          <Image src={data?.icon.url} alt={data?.icon.description} />
        </Box>
        <Box sx={styles.featureText}>
          <Heading as="h4">{data?.title}</Heading>
          <Text as="p">{data?.description}</Text>
          {data?.path && <LearnMore path={data?.path} />}
        </Box>
      </Grid>
    );
  }
  else {
    return (
      <Grid gap={2} columns={[1, null, 2]} {...props} sx={styles.feature}>
        <Box sx={styles.featureText}>
          <Heading as="h4">{data?.title}</Heading>
          <Text as="p">{data?.description}</Text>
          {data?.path && <LearnMore path={data?.path} />}
        </Box>
        <Box as="figure" sx={{marginTop: '2rem'}}>
          <Image src={data?.icon.url} alt={data?.icon.description} />
        </Box>
      </Grid>
    );
  }
};

export default Feature;

const styles = {
  feature: {
    display: null,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginY: '10rem',
    px: [3, null, null, null, 'unset'],
    figure: {
      minWidth: [42, null, null, 70],
      maxWidth: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      mb: [3, null, null, null, null, 5],
      img: {
        mx: ['auto'],
      },
    },
    h4: {
      fontSize: [5, null, null, 8],
      fontWeight: 'bold',
      lineHeight: 1.28,
      color: 'heading',
      mb: 3,
    },
    p: {
      fontSize: [1, null, null, 2],
      lineHeight: 1.88,
      color: 'text',
    },
    a: {
      mt: [3],
    },
  },
  featureText: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center'
  }
};
