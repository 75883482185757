/** @jsx jsx */
import { jsx, Box, Button } from 'theme-ui';
import { IoMdCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { useAuth0 } from '@auth0/auth0-react';

const FeatureIcon = ({ value }) => {
  return value ? (
    <IoMdCheckmarkCircle size="23px" color="#3CC68A" />
  ) : (
    <IoIosCloseCircle size="23px" color="#CED7E1" />
  );
};

const PriceTable = ({ data }) => {
  const {
    loginWithRedirect,
  } = useAuth0();

  return (
    <div sx={styles.card}>
      <div
        sx={styles.priceTable}
      >
        <span sx={styles.title}>{data.title}</span>
        <span sx={styles.amount}>
          {data.plancost}
        </span>
        <Box as="ul" variant="styles.unStyled" sx={styles.features}>
          <li>
            <span>All Supported Languages</span>
            <FeatureIcon value={data.allLanguages} />
          </li>
          <li>
            <span>Usage Limit</span>
            <code>{data.docs}</code>
          </li>
          <li>
            <span>Access to CLI</span>
            <FeatureIcon value={data.cli} />
          </li>
          <li>
            <span>Data Residency Control</span>
            <FeatureIcon value={data.dataResidency} />
          </li>
          <li>
            <span>HIPAA & SOC 2 Compliance</span>
            <FeatureIcon value={data.compliance} />
          </li>
          <li>
            <span>Priority Support</span>
            <FeatureIcon value={data.prioritySupport} />
          </li>
        </Box>
        <Button
          variant="primaryOutline"
          onClick={() => {
              if(data.button === "contact@trelent.net") {
                window.location.href = "mailto:contact@trelent.net";
              }
              else if(data.button === "Get Started") {
                window.open("https://marketplace.visualstudio.com/items?itemName=Trelent.trelent", "_blank");
              }
              else {
                loginWithRedirect({"screen_hint": "signup", "redirectUri": "https://docgen.trelent.net"})
              }
            }
          }
        >
          {data.button}
        </Button>
      </div>
    </div>
  );
};

export default PriceTable;

const styles = {
  card: {
    position: 'relative',
    textAlign: 'center',
    '&.recommended': {
      borderColor: [null, null, null, null, 'white'],
      borderRadius: '0px 0px 10px 10px',
      boxShadow: [
        null,
        null,
        null,
        null,
        '0px 15px 50px rgba(91, 132, 193, 0.1)',
      ],
      priceTable: {
        borderRadius: '0 0 10px 10px',
      },
    },
    mx: [null, null, null, 3, 0],
  },
  priceTable: {
    border: (t) => `1px solid ${t.colors.muted}`,
    borderRadius: 10,
    paddingTop: [4, null, null, null, 6],
    paddingBottom: [4, null, null, null, 6, 6],
    '&.recommended': {
      borderColor: [null, null, null, null, 'muted'],
      borderRadius: '0 0 10px 10px',
    },
  },
  recommended: {
    backgroundColor: 'primary',
    borderRadius: '8px 8px 0px 0px',
    color: 'text',
    fontSize: 1,
    fontWeight: 700,
    lineHeight: 1.29,
    position: 'absolute',
    width: '100%',
    minHeight: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: ['-30px', null, null, null, '-31px'],
    textTransform: 'uppercase',
  },
  title: {
    color: 'text',
    display: 'block',
    fontWeight: 500,
    fontSize: [1, null, null, 2],
    lineHeight: 1.31,
    mb: [3, null, null, 5, 2, 5],
  },
  amount: {
    color: 'textSecondary',
    display: 'block',
    fontWeight: 'bold',
    fontSize: [4, null, null, 7, 4, 7],
    lineHeight: [1.3, null, null, 1.71],
    letterSpacing: 'heading',
    small: {
      fontSize: [2, null, null, 4, 2, 4],
      fontWeight: 400,
    },
  },
  features: {
    my: [4, null, null, 8, 2, 8],
    li: {
      alignItems: 'center',
      borderTop: (t) => `1px solid ${t.colors.borderColor}`,
      display: 'flex',
      color: ['text', null, null, null, 'textSecondary'],
      fontSize: [1, null, null, null, 2],
      fontWeight: [500, null, null, null, 400],
      justifyContent: ['space-between', null, null, null, 'center'],
      px: [6, null, null, null, 0],
      minHeight: [null, null, null, null, 50, 59],
      pt: [3, null, null, null, 0],
      pb: [3, null, null, null, 0],
      ':first-of-type': {
        borderTop: 0,
        pt: 0,
      },
      ':last-of-type': {
        pb: 0,
      },
      span: {
        display: [null, null, null, null, 'none'],
      },
      svg: {
        height: 20,
        width: 20,
      },
    },
  },
  trial: {
    display: 'block',
    color: 'textSecondary',
    fontSize: ['13px', null, null, '15px'],
    lineHeight: 1.33,
    mt: [3, null, null, 5],
  },
};
