/** @jsx jsx */
import { jsx, Container } from 'theme-ui';
import Masonry from 'react-masonry-component';
import SectionHeading from 'components/section-heading';
import FaqItem from 'components/cards/faq-item';

const masonryOptions = { originTop: false };

const Faq = () => {
  // Local state
  const faqs = [
    {
      "id": 0,
      "question": "How does Trelent work?",
      "answer": "Trelent analyzes the source code you upload using sophisticated Deep Learning models, and attempts to answer the 'why' instead of the 'what' for your code."
    },
    {
      "id": 1,
      "question": "Does Trelent store my source code?",
      "answer": "Trelent stores your anonymized source code on our free plans to help improve our service. We do not store your source code on our paid plans. To opt out, please upgrade or discontinue use immediately. You are responsible for ensuring you own the code you submit to Trelent, or have prior permission from the owner to do so. Please see our Terms of Use and Privacy Policy for more details."
    },
    {
      "id": 2,
      "question": "How secure is my code?",
      "answer": "We are working towards SOC Type 2 certification so that you can be sure your data is not at risk. Your data is fully encrypted in transit and at rest."
    },
    {
      "id": 3,
      "question": "What programming languages does Trelent support?",
      "answer": "Currently we support C#, Java, Javascript and Python. C, C++ and VB are on our roadmap."
    },
    {
      "id": 4,
      "question": "What kind of documentation does Trelent automate?",
      "answer": "At the moment we write docstrings for you. Various forms of external documentation are on our roadmap."
    },
    {
      "id": 5,
      "question": "How do I use Trelent?",
      "answer": "Add Trelent to your favourite IDE, then click on a function you'd like to document. Then it's as easy as pressing Alt + D!"
    }
  ];

  faqs.sort((a, b) => (a.id > b.id) ? 1 : -1);

  return (
    <section id="faq" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={{ mb: 15 }}
          title="Frequently asked questions"
        />
        <Masonry options={masonryOptions} sx={styles.grid}>
          {faqs.map((item) => {
            return <FaqItem key={item.id} faq={item} className="faq-item" />;
          })}
        </Masonry>
      </Container>
    </section>
  );
};

export default Faq;

const styles = {
  section: {
    pt: [8, null, null, null, 10, 14],
    pb: [null, null, null, null, null, 10, 14],
  },
  grid: {
    mx: [null, null, null, -6, -8, 'unset'],
  },
};
