/** @jsx jsx */
import { jsx, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import Feature from 'components/cards/feature';

const Services = () => {
  // Local state
  const services = [
    {
      "id": 0,
      "icon": {
        "url": "https://images.ctfassets.net/djejiu8c4qza/3TyRBTZSdt3RvK7Wk9TKcu/d443fdeefe548af431ac346724678654/undraw_in_no_time_6igu.svg",
        "description": "A person standing next to a watch."
      },
      "title": "Improve efficiency",
      "description": "By automating the generation of docstrings, you let your developers spend an extra 10% of their day doing what they do best - developing."
    },
    {
      "id": 1,
      "icon": {
        "url": "https://images.ctfassets.net/djejiu8c4qza/34GMT0T0yzlhSbChkl8kzS/429347e94f948ee53baf9028d1508d70/undraw_things_to_say_ewwb.svg",
        "description": "A person in nature with a speech bubble next to them."
      },
      "title": "Reduce miscommunication",
      "description": "Keep communication between developers consistent, without the effort. Our docstrings dramatically improve documentation clarity."
    },
    {
      "id": 2,
      "icon": {
        "url": "https://images.ctfassets.net/djejiu8c4qza/7C7twDkPKDiKNPTR0lffVT/79dc30cccfe7861e6276b22fa50040bb/undraw_Partying_re_at7f.svg",
        "description": "A group of people celebrating."
      },
      "title": "Increase team happiness",
      "description": "Developers are happier when they don't have to argue over whether or not to document. Happy devs, happy life!"
    }
  ]

  // Ensure our styles use the services count
  const styles = {
    section: {
      backgroundColor: 'muted',
      pt: [12],
      pb: [6, null, null, 12, 15],
    },
    heading: {
      marginBottom: [40, 50, 60, 80],
      maxWidth: ['none', null, null, 565, null, 'none'],
    },
    features: {
      gap: [6, null, null, 8],
      display: null,
      maxWidth: 1175,
      mx: 'auto',
      justifyContent: ['center', null, null, 'unset'],
      gridTemplateColumns: [null, null, null, `repeat(3, 1fr)`],
      '.slick-list': {
        paddingTop: ['31px', null, null, null, 0],
      },
      '.slick-dots': {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        li: {
          display: 'flex',
        },
        button: {
          backgroundColor: '#CED7E1',
          border: 0,
          outline: 0,
          padding: 0,
          margin: '0 3.5px',
          width: 10,
          height: 10,
          borderRadius: '50%',
          overflow: 'hidden',
          textIndent: '-9999rem',
          transition: 'all 0.3s ease-in-out 0s',
        },
        '.slick-active button': {
          backgroundColor: 'heading',
          width: 13,
          height: 13,
        },
      },
    },
  };


  return (
    <section id="services" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          slogan="Up to 10% of a developer's day is spent writing documentation instead of building product."
          title="Trelent let's developers keep developing."
        />
        {services?.map((item) => (
          <Feature key={item.id} className="feature-item" data={item} />
        ))}
      </Container>
    </section>
  );
};

export default Services;
